<template>
    <div class="walkthrough bg-light text-primary">
        <img alt="" src="@/assets/logo_primary.svg" />
        <div key="step-1" :class="{ active: step === 1 }" class="step">
            <img alt="" src="@/assets/bird_walkthrough_1.svg" />
            <h2>Welcome!</h2>
            <!-- <h2>Velkommen!</h2> -->
            <p>
                <!-- Hei! Din nye underholdningsverden er her. Er du fan, artist eller har en scene:
                Velkommen til Goosebumper hvor dine drømmer blir virkelighet! -->
                Your brand new world of entertainment is here. Are you a fan, artist, or a
                stage-owner? Welcome to Goosebumper, where your dreams come true!
            </p>
        </div>
        <div key="step-2" :class="{ active: step === 2 }" class="step">
            <img alt="" src="@/assets/bird_walkthrough_2.svg" />
            <h2>About you</h2>
            <!-- <h2>Litt om deg!</h2> -->
            <p>
                <!-- Hos Goosebumper lager du enkelt en profil, øker nettverket ditt og mottar siste
                nytt. Finn, tilby eller skap opplevelser. -->
                With goosebumper, you can easily create a profile, expand your network, and receive
                the latest news. Find, offer, and create magical experiences.
            </p>
        </div>
        <div key="step-3" :class="{ active: step === 3 }" class="step">
            <img alt="" src="@/assets/bird_walkthrough_3.svg" />
            <h2>A new world!</h2>
            <!-- <h2>En ny verden!</h2> -->
            <p>
                <!-- Så sett i gang! Det er enkelt, gøy og proft. Det sosiale er gjenoppfunnet - via
                opplevelser som gir Goosebumps! -->
                So let's get started! It's easy and fun. Togetherness is reinvented - through
                experiences that will give you GOOSEBUMPS!
            </p>
        </div>
        <div class="progress">
            <span :class="{ active: step === 1 }" @click="step = 1"></span>
            <span :class="{ active: step === 2 }" @click="step = 2"></span>
            <span :class="{ active: step === 3 }" @click="step = 3"></span>
        </div>
        <!-- <button v-if="step === 1" class="button" @click="step = 2">Wow, tell me more</button> -->
        <!-- <button v-if="step === 2" class="button" @click="step = 3">Nice, tell me more</button> -->
        <!-- <button v-if="step === 3" class="button" @click="$router.replace('/dashboard')"> -->
        <Button v-if="step === 1" label="Wow, tell me more" @click="step = 2" primary />
        <Button v-if="step === 2" label="Nice, tell me more" @click="step = 3" primary />
        <Button v-if="step === 3" label="Continue" @click="$router.replace('/dashboard')" primary />
    </div>
</template>

<script>
export default {
    name: 'walkthrough',
    data() {
        return {
            step: 1
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables';

.walkthrough {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 40px 20px;

    /*    .carousel {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            width: 24em;
        }*/

    .step {
        text-align: center;
        padding-top: 10vh;
        transition: opacity 0.3s ease-in-out;
        display: block;
        opacity: 1;

        &:not(.active) {
            opacity: 0;
            display: none;
        }
    }

    .progress {
        display: flex;
        width: 50px;
        justify-content: space-between;
        padding: 20px 0 30px;

        span {
            height: 10px;
            width: 10px;
            background-color: transparent;
            border: 1px solid $primary;
            border-radius: 5px;

            &.active {
                background: $primary;
            }
        }
    }
}
</style>